import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { Button, Modal } from 'reactstrap';

import trackEvent from '../analytics';

import Video1 from '../../images/Video_1.jpg';
import Video2 from '../../images/Video_2.jpg';
import Video3 from '../../images/Video_3.jpg';
import Video4 from '../../images/Video_4.jpg';

const ViewModal = styled(Modal)`
  max-width: 100%;
  margin: 0;

  .modal-content {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row nowrap;
    max-width: 100%;
  }
`;

const ViewVideos = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 5rem 2.5rem;
  animation: bounceIn;
  animation-duration: 1s;
`;

const ViewVideo = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  border-radius: 4px;
  margin: 0.5%;
  height: auto;
  cursor: pointer;
  border: 3px solid #333;
  box-shadow: 0 0 60px #444;
  animation: bounceIn;
  animation-duration: 1s;

  img {
    max-width: 216px;
    max-height: 116px;
  }

  &::before {
    position: absolute;
    z-index: 1000;
    margin: auto;
    color: #000;
    width: 30px;
    height: 30px;
    font-weight: bold;
    border-radius: 100px;
    background: #ffc107;
    margin-right: 4px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const ViewVideo1 = styled(ViewVideo)`
  &::before {
    content: '1';
  }
`;

const ViewVideo2 = styled(ViewVideo)`
  &::before {
    content: '2';
  }
`;

const ViewVideo3 = styled(ViewVideo)`
  &::before {
    content: '3';
  }
`;

const ViewVideo4 = styled(ViewVideo)`
  &::before {
    content: '4';
  }
`;

const ViewButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
`;

const ShareButtons = () => (
  <Fragment>
    <FacebookShareButton
      url={'https://findietou.com/di/part-1-videos/'}
      title={
        'Découvre l’application FinDiETou et les vidéos du module “Gérer les revenus de mon activité””'
      }
      quote={
        'Bienvenue dans la Partie 1 qui t’apprendra à Gérer les revenus de ton activité. Elle te donnera des conseils pour comprendre et bien gérer tes revenus, tes dépenses, et tes bénéfices. Bonne formation !'
      }
      hashtag="#FinDiETou"
      onClick={() => {
        trackEvent('Video Share Buttons', 'User shared module 1 on Facebook', 'yes');
      }}
    >
      <FacebookIcon size={48} />
    </FacebookShareButton>
    <TwitterShareButton
      url={'https://findietou.com/di/part-1-videos/'}
      title={
        'Découvre l’application FinDiETou et les vidéos du module “Gérer les revenus de mon activité”'
      }
      hashtag="#FinDiETou"
      onClick={() => {
        trackEvent('Video Share Buttons', 'User shared module 1 on Twitter', 'yes');
      }}
    >
      <TwitterIcon size={48} />
    </TwitterShareButton>
    <WhatsappShareButton
      url={'https://findietou.com/di/part-1-videos/'}
      title={
        'Découvre l’application FinDiETou et les vidéos du module “Gérer les revenus de mon activité”'
      }
      separator="-"
      onClick={() => {
        trackEvent('Video Share Buttons', 'User shared module 1 on Whatsapp', 'yes');
      }}
    >
      <WhatsappIcon size={48} />
    </WhatsappShareButton>
  </Fragment>
);

const Part1Videos = () => {
  const audioStop = () => {
    if (typeof window !== 'undefined') {
      var audio = document.getElementById('audio');
      audio.pause();
    }
  };

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => {
    setModal1(!modal1);
    audioStop();
  };

  const [modal2, setModal2] = useState(false);
  const toggle2 = () => {
    setModal2(!modal2);
    audioStop();
  };

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => {
    setModal3(!modal3);
    audioStop();
  };

  const [modal4, setModal4] = useState(false);
  const toggle4 = () => {
    setModal4(!modal4);
    audioStop();
  };

  return (
    <ViewVideos>
      <ViewVideo1>
        <img src={Video1} onClick={toggle1} />
      </ViewVideo1>
      <ViewVideo2>
        <img src={Video2} onClick={toggle2} />
      </ViewVideo2>
      <ViewVideo3>
        <img src={Video3} onClick={toggle3} />
      </ViewVideo3>
      <ViewVideo4>
        <img src={Video4} onClick={toggle4} />
      </ViewVideo4>

      <ViewModal isOpen={modal1} toggle={toggle1}>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            className="embed-responsive-item"
            // src="https://www.youtube.com/embed/lYaSCza7iBc?si=TnEAVU4ZH5JIq4OG"
            src="https://www.youtube.com/embed/jLKVOWSkOxM?si=O5aw4XVSEfPCUH4B"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <ViewButtons>
          <Button color="warning" size="lg" onClick={toggle1}>
            <i className="fa fa-times" />
          </Button>
          <ShareButtons />
        </ViewButtons>
      </ViewModal>

      <ViewModal isOpen={modal2} toggle={toggle2}>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/AhHn2umbTBg?si=k8IrBLcjxdiNovv8"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <ViewButtons>
          <Button color="warning" size="lg" onClick={toggle2}>
            <i className="fa fa-times" />
          </Button>
          <ShareButtons />
        </ViewButtons>
      </ViewModal>

      <ViewModal isOpen={modal3} toggle={toggle3}>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/RIWKZw6RYes"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <ViewButtons>
          <Button color="warning" size="lg" onClick={toggle3}>
            <i className="fa fa-times" />
          </Button>
          <ShareButtons />
        </ViewButtons>
      </ViewModal>

      <ViewModal isOpen={modal4} toggle={toggle4}>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            width="560"
            height="315"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/v1AQ_wi-9KA?si=rxKIM21wTb5jjzNJ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <ViewButtons>
          <Button color="warning" size="lg" onClick={toggle4}>
            <i className="fa fa-times" />
          </Button>
          <ShareButtons />
        </ViewButtons>
      </ViewModal>
    </ViewVideos>
  );
};

export default Part1Videos;
